<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
  >
    <template v-slot:sourceFile="{ row }">
      <a
        v-if="row.sourceFile"
        class="editable-list__link"
        @click="onDownloadFile(row.id)"
      >
        {{ row.sourceFile.fileName }}
      </a>
    </template>
  </sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'MeterValuesReaderStatistic',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'MeterValuesReaderStatistic',
      tableCaption: 'Cбор данных показаний - фотографии',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Cбор данных показаний - фотографии',
        },
      ],
      tableHeaders: [
        { text: 'Дата загрузки', alias: 'moment', order: 'moment' },
        { text: 'Название базы', alias: 'baseName', order: 'baseName' },
        { text: 'Л/С', alias: 'ident', order: 'ident' },
        { text: 'Заводской номер ИПУ', alias: 'meter', order: 'meter' },
        {
          text: 'Номера запроса',
          alias: 'requestId',
          order: 'requestId',
        },
        {
          text: 'Результат распознования',
          alias: 'requestResult',
          order: 'requestResult',
        },
        { text: 'Ресурс', alias: 'resourceName', order: 'resourceName' },
        {
          text: 'Имя загруженного файла',
          alias: 'sourceFile',
          order: 'sourceFile',
        },
        { text: 'Название клиента', alias: 'clientName', order: 'clientName' },
      ],
    };
  },

  methods: {
    ...mapActions(['downloadFile']),

    onDownloadFile(id) {
      this.downloadFile({
        name: this.controllerName,
        id,
        type: 'file',
      });
    },
  },
};
</script>
